/* eslint-disable no-magic-numbers */
import { array, object, string } from 'yup';

import UIConfig from '@/config/ui.config';
import UIConstants from '@/constants/ui';

const viewEditorBasicPropertiesSchema = object({
  departments: array().min(1, UIConstants.DEPARTMENTS_ERROR_MESSAGE).required(),
  templates: array().min(1, UIConstants.TEMPLATES_ERROR_MESSAGE).required(),
  viewName: string()
    .trim()
    .required(UIConstants.VIEW_NAME_NOT_BLANK)
    .min(UIConfig.MINIMUM_VIEW_NAME_LENGTH, UIConstants.VIEW_NAME_MIN_LENGTH_ERROR_MESSAGE)
    .max(UIConfig.MAXIMUM_VIEW_NAME_LENGTH, UIConstants.VIEW_NAME_MAX_LENGTH_ERROR_MESSAGE),
});

export default viewEditorBasicPropertiesSchema;
