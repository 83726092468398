/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { BaseSyntheticEvent } from 'react';

import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import NavBlocker from '@/components/view-editor/NavBlocker';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { makeSelectTemplatesForSelectedDepartments } from '@/store/slices/departmentsAndTemplates.slice';
import { setViewEditorDraftName, setViewEditorDraftThemeDataLayoutDataType } from '@/store/slices/viewEditor.slice';
import { RootState } from '@/store/store';
import { DropdownAction } from '@/types/ui.types';
import { ViewLayoutDataType, ViewLayoutType } from '@/types/view.types';
import viewEditorBasicPropertiesSchema from '@/utils/validationSchemas/viewEditorBasicProperties.validation.schema';

import ViewLayout from './ViewLayout';

export interface ViewNameFieldProps {
  field: {
    name: string;
    value: string;
  };
  form: {
    errors: { [field: string]: string };
    touched: { [field: string]: boolean };
  };
}

export interface ViewDropdownFieldProps {
  field: {
    name: string;
    value: number[];
  };
  form: {
    errors: { [field: string]: string };
    touched: { [field: string]: boolean };
  };
}

interface BasicPropertiesProps {
  handleDepartmentOptionsChange: (selectedDepartmentIds: number[], action?: DropdownAction) => void;
  handleTemplateOptionsChange: (selectedTemplateIds: number[]) => void;
}

const selectedTabStyle = {
  color: 'white',
};

const dataTypeTabIndices = Object.values(ViewLayoutDataType);

const ViewEditorBasicProperties = (props: BasicPropertiesProps): React.JSX.Element => {
  const { handleDepartmentOptionsChange, handleTemplateOptionsChange } = props;

  const {
    uiState: { selectedDepartments: selectedDepartmentIds, selectedTemplates: selectedTemplateIds },
    viewDraft: view,
  } = useAppSelector((state) => state.viewEditor);

  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);

  const templateForSelectedDepartmentsSelector = React.useMemo(() => makeSelectTemplatesForSelectedDepartments(), []);

  const selectTemplatesForSelectedDepartments = useAppSelector((state: RootState) =>
    templateForSelectedDepartmentsSelector(state, selectedDepartmentIds),
  );

  const dispatch = useAppDispatch();

  // eslint-disable-next-line no-magic-numbers
  if (!view || Object.keys(view).length === 0) {
    return <Box>View not found</Box>;
  }

  const handleTabSelectorChange = (index: number) => {
    const dataType = dataTypeTabIndices[index];

    dispatch(setViewEditorDraftThemeDataLayoutDataType(dataType));
  };

  const getDataTypeContainer = (): React.JSX.Element => {
    const selectedIndex = dataTypeTabIndices.indexOf(view.theme.data.dataType ?? ViewLayoutDataType.REQUEST);

    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <Text>Data Type</Text>
        <Tabs
          variant={'unstyled'}
          position={'relative'}
          index={selectedIndex}
          onChange={handleTabSelectorChange}
          isLazy
        >
          <TabList>
            <Tab _selected={selectedTabStyle}>Request</Tab>
            <Tab _selected={selectedTabStyle}>Schedule</Tab>
            <Tab _selected={selectedTabStyle}>Both</Tab>
          </TabList>
          <TabIndicator
            bg={'blue.500'}
            borderRadius={'10px'}
            height={'40px'}
            mt={'-40px'}
            zIndex={-1}
            position={'inherit'}
            textColor={'white'}
          />
        </Tabs>
      </VStack>
    );
  };

  return (
    <Box>
      <VStack align={'left'} justifyContent={'space-between'} gap={'5'}>
        <Formik
          initialValues={{
            departments: view.filter.on_departments,
            templates: view.filter.on_templates,
            viewName: view.name,
          }}
          onSubmit={() => {
            return;
          }}
          validationSchema={viewEditorBasicPropertiesSchema}
        >
          {({ setFieldValue, setTouched }) => (
            <Form>
              <Field name="viewName" value={view.name}>
                {({ field, form }: ViewNameFieldProps) => (
                  <FormControl
                    onChange={(e: BaseSyntheticEvent) => dispatch(setViewEditorDraftName(e.target.value))}
                    isRequired
                    w={'100%'}
                    isInvalid={(form.errors.viewName && form.touched.viewName) as boolean}
                    onBlur={() =>
                      setTouched({
                        ...form.touched,
                        viewName: true,
                      })
                    }
                  >
                    <FormLabel>View Name</FormLabel>
                    <Input {...field} type={'text'} placeholder={'Enter a name for this view'}></Input>
                    <FormErrorMessage>{form.errors.viewName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <HStack w={'min-content'} align={'flex-start'} mt={'20px'}>
                <Field w={'auto'} name="departments">
                  {({ form }: ViewDropdownFieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={(form.errors.departments && form.touched.departments) as boolean}
                      onBlur={() =>
                        setTouched({
                          ...form.touched,
                          departments: true,
                        })
                      }
                    >
                      <DepartmentsDropdown
                        isRequired
                        departmentList={departments}
                        departmentChangeHandler={(values: number[], action?: DropdownAction) => {
                          handleDepartmentOptionsChange(values, action);
                          setFieldValue('departments', values);
                        }}
                        selectedIds={selectedDepartmentIds}
                      />
                      <FormErrorMessage>{form.errors.departments}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field w={'auto'} name="templates">
                  {({ form }: ViewDropdownFieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={(form.errors.templates && form.touched.templates) as boolean}
                      onBlur={() =>
                        setTouched({
                          ...form.touched,
                          templates: true,
                        })
                      }
                    >
                      <TemplatesDropdown
                        isRequired
                        templateList={selectTemplatesForSelectedDepartments}
                        templateChangeHandler={(values: number[]) => {
                          handleTemplateOptionsChange(values);
                          setFieldValue('templates', values);
                        }}
                        selectedIds={selectedTemplateIds}
                      />
                      <FormErrorMessage>{form.errors.templates}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <NavBlocker />
            </Form>
          )}
        </Formik>

        {view.theme.data.layout !== ViewLayoutType.BLOCK && getDataTypeContainer()}
        <ViewLayout />
      </VStack>
    </Box>
  );
};

export default ViewEditorBasicProperties;
